
$prime: #f16601c2;

@import "style.scss";


.navbar-nav .nav-item .nav-link {
    color: white;
}

.call-to-action {
    color: white;
    a{
        color: white;
    }
}

.course_home{
    .flag-image{
        display: block;
        width: 100%;
    }
}